import { FC, HTMLAttributes, ReactNode, RefObject, TransitionEvent, useEffect, useRef } from 'react';
import block from 'utils/bem-css-module';

import style from './modal.scss';

type Props = {
	isOpen: boolean;
	children?: ReactNode;
	className?: string;
	id: string;
	afterCLose?: () => void;
	contentRef?: RefObject<HTMLDivElement>;
	withMobileStretch?: boolean;
}

const b = block(style);
const MAIN_CONTAINER = document.getElementsByClassName('wrapper')[0]! as HTMLDivElement;

export const Modal: FC<Props & HTMLAttributes<HTMLElement>> = ({
	isOpen,
	children,
	className,
	id,
	onTransitionEnd,
	afterCLose,
	contentRef,
   	withMobileStretch = false,
	...rest
}) => {
	const headerId = `${id}-header`;
	const bodyId = `${id}-body`;

	const localContentRef = contentRef || useRef<HTMLElement>(null);

	const onTransition = (e: TransitionEvent<HTMLElement>): void => {
		const content = localContentRef.current;
		if (e.currentTarget === e.target && content) {
			// trap focus
			if (isOpen && e.propertyName === 'background-color') {
				content.focus();
			}
			if (!isOpen && e.propertyName === 'opacity') {
				afterCLose?.();
			}
		}
		onTransitionEnd?.(e);
	};

	useEffect(() => {
		const content = localContentRef.current;
		if (content && isOpen) {
			content.focus();
			MAIN_CONTAINER.style.overflow = 'hidden';
		}
		if (!isOpen) {
			MAIN_CONTAINER.style.overflow = 'unset';
		}
	}, [isOpen]);

	return (
		<dialog
			className={b({ open : isOpen, stretch: withMobileStretch })}
			role='dialog'
			id={id}
			aria-labelledby={headerId}
			aria-describedby={bodyId}
			aria-modal='true'
			aria-hidden={!isOpen}
			data-open={isOpen}
			onTransitionEnd={onTransition}
			open={isOpen}
			inert={isOpen ? undefined : ''}
			{...rest}
		>
			<article
				className={b('content').mix(className)}
				tabIndex={isOpen ? 0 : -1}
				ref={localContentRef}
			>
				{children}
			</article>
		</dialog>
	);
};
